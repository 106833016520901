// Product Panel
.product-panel {
  margin-top: 5px;
}

.info-icon-ext {
  right: 0 !important;
  bottom: 36px !important;
}

$margin-card-summry: 10px;

.product-panel {
  display: flex;
  flex-direction: column;
  background: $color-gray-light5;
  padding: 30px;
  border-radius: 8px;

  .product-panel-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    .vehicle-details {
      display: flex;
      align-items: center;

      .vehicle-icon-background {
        display: flex;
        align-items: center;
        justify-content: center;
        background: $color-gray-light;
        border-radius: 50%;
        width: 60px;
        height: 60px;

        img {
          width: 40%;
          height: auto;
        }
      }

      .vehicle-icon {
        background: url("../images/motorcycle.png");
        width: 100%;
        height: 32px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
      }

      .vehicle-info {
        display: flex;
        flex-direction: column;

        .vehicle-reg {
          font-size: 18px;
          color: $color-body-font;
          font-weight: bold;
        }

        .vehicle-model {
          font-size: 14px;
          color: $color-body-font;
          font-weight: 400;
        }
      }
    }
  }
}

// Product Panel Card

.products-panel-cards {
  display: flex;
  flex-wrap: wrap;
  // grid-gap: 20px;
  //  grid-template-columns: repeat(4, 1fr);

  .mbi-card {
    display: flex;
    flex-direction: column;
    background: $color-gray-light2;
    // flex-basis: calc(25% - 20px);
    max-width: 310px;
    border: 0;
    width: 100%;
    margin-right: 20px;
    margin-bottom: 10px;
    &:nth-child(4n) {
      margin-right: 20px;
    }

    .icon-header-panel {
      display: flex;
      align-items: center;
      justify-content: center;
      background: $color-gray-light;
      width: 100%;
      height: 80px;
      overflow: hidden;

      .mbi-icon {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: auto;
      }

      .gold {
        // background: url('{{ get_asset_url("./images/gold.png")}}');
        background-repeat: no-repeat;
        background-position: center;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      img {
        max-width: 50%;
      }

      .road {
        background: url("../images/road.png");
        background-repeat: no-repeat;
        background-position: center;
        width: 100%;
        height: 100%;
      }
    }

    .card-body {
      display: flex;
      flex-direction: column;
    }

    .mbi-title {
      font-size: 18px;
      color: $color-body-font;
      font-weight: bold;
      padding: 0;
    }

    .mbi-validity-dangerous {
      font-size: 12px;
      color: $color-red;
      font-weight: bold;
    }

    .mbi-validity-renewed {
      font-size: 12px;
      color: $color-gray-dark;
      font-weight: bold;
    }

    .mbi-validity-default {
      font-size: 12px;
      color: $color-green;
      font-weight: bold;
    }
    .mbi-id {
      font-weight: bold;
      font-size: 12px;
      color: $color-gray-light7;
    }
    .mbi-btn-wrapper {
      // gap: 20px;
      a {
        // width: 50%;
        width: calc(50% - #{$margin-card-summry});
        font-size: 11px;
        border-radius: 5px;

        &:first-child {
          margin-right: $margin-card-summry;
        }
        &:last-child {
          margin-left: $margin-card-summry;
        }
      }
      .align-center {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .Price-wrapper {
      // gap: 20px;
      span {
        // width: 50%;
        width: calc(50% - #{$margin-card-summry});

        &:first-child {
          margin-right: $margin-card-summry;
        }
        &:last-child {
          margin-left: $margin-card-summry;
        }
      }
    }
  }
}

// Products page styles

.price {
  margin: 5px 0;
  .yearly-price,
  .monthly-price {
    font-size: 12px;
    color: $color-primary;
    font-weight: 700;
  }
  .month {
    font-size: 12px;
    color: $color-primary;
    font-weight: 400;
    white-space: nowrap;
  }
}

.new-price {
  .yearly-price,
  .monthly-price {
    color: #ff4141;
  }
  .month {
    color: #ff4141;
  }
}

.del-style {
  text-decoration-thickness: 2px;
}

.section-header {
  .section-info {
    .main-title-ext {
      border-bottom-width: 1px !important;
      border-bottom-color: #80808024;
      border-bottom-style: solid;
      margin-bottom: 10px !important;
      padding-bottom: 2px;
    }
    .main-title {
      font-size: 20px;
      font-weight: 700;
      color: $color-primary;
      margin: 5px 0;
    }
    .secondary-title {
      font-size: 12px;
      font-weight: 400;
      color: $color-gray-light6;
      margin: 10px 0 5px;
    }
  }
  .countdown {
    font-size: 14px;
    font-weight: 400;
    color: $color-red;
  }
}
.vehicle-summary-wrap {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  margin-bottom: 10px;
  > div {
    width: 100%;
    height: 100%;
    .cart-card {
      margin-bottom: 0;
      flex: 1;
      height: 100%;
    }
  }
}

.vehicle-summary-card {
  background: $color-gray-light5;
  padding: 10px 10px 0 10px;
  //  flex-basis: calc(50% - #{$margin-card-summry});
  width: calc(50% - #{$margin-card-summry});
  border-radius: 8px;
  box-sizing: border-box;
  margin-right: $margin-card-summry;

  .vehicle-icon-background {
    display: flex;
    align-items: center;
    justify-content: center;
    background: $color-gray-light;
    border-radius: 5px;
    width: 40px;
    height: 40px;

    img {
      width: 50%;
      height: auto;
    }

    .vehicle-icon {
      background: url("../images/motorcycle.png");
      width: 32px;
      height: 32px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }
  .vehicle-info {
    p {
      font-size: 12px;
      font-weight: 700;
      color: $color-gray-light6;
      margin: 0px;
    }
    h3 {
      font-size: 14px;
      font-weight: 700;
      color: $color-gray-dark1;
      margin: 4px 0;
    }
  }

  .coverage-info {
    h5 {
      font-size: 14px;
      font-weight: 400;
      color: $color-gray-dark1;
      margin: 5px 0;
    }
    div {
      font-size: 12px;
      font-weight: 400;
      color: $color-gray-light6;
      margin: 0px;
    }
    .small-txt {
      font-size: 11px;
      max-width: 350px;
      width: 100%;
      margin-right: 20px;
    }
  }

  .coverage-info-wrap {
    .mbi-validity-default {
      max-width: 100px;
      width: 100%;
      text-align: right;
    }
  }

  .countdown {
    font-size: 14px;
    font-weight: 400;
    color: $color-red;
  }

  .sub-title-typ-1 {
    margin-top: 10px;
  }
}

.richtext-cart-card {
  background: $color-gray-light5;
  padding: 20px;
  margin-right: 0;
  border-radius: 8px;
  box-sizing: border-box;
  margin-left: $margin-card-summry;
  width: calc(50% - 10px);
}

.cart-card {
  background: $color-gray-light5;
  padding: 10px 10px 0 10px;
  margin-right: 0;
  border-radius: 8px;
  box-sizing: border-box;
  width: 100%;
  margin-left: 0;
  .column {
    h6 {
      font-size: 12px;
      font-weight: 400;
      color: $color-gray-light6;
      margin: 5px 0 0;
    }
    h4 {
      font-size: 12px;
      font-weight: 700;
      color: $color-gray-dark1;
      margin-bottom: 0;
      margin-top: 10px;
    }
    h4.price {
      color: $color-primary;
    }
    .column-or {
      font-size: 12px;
      background-color: $color-primary;
      border-radius: 3px;
      color: $color-white;
      padding: 3px 5px;
    }
    .sepa {
      position: absolute;
      left: 50%;
      top: 115%;
      width: 1px;
      background-color: $color-gray-light8;
      height: 15px;
      text-align: center;
      margin: 7px auto;
    }
  }
  .card-empty {
    font-size: 14px;
    font-weight: 700;
    color: $color-gray-light7;
    text-align: center;
    padding: 15px 180px 0 180px;
    margin-bottom: 0;
  }
  .width-adjust {
    width: 130px;
  }
  .pading-adjest {
    padding-left: 20%;
  }
}

.cart-item-name {
  width: 55%;
}

.price-meter {
  span {
    font-size: 18px;
    font-weight: 400;
    padding-right: 10px;
    line-height: 18px;
  }
  h3 {
    font-size: 18px;
    color: $color-primary;
    font-weight: 700;
    margin: 0;
  }
}

.selected-mbi {
  border: 5px solid $color-green !important;
}

.form-validation {
  font-size: 12px;
  font-weight: 400;
  color: $color-red;
}

.info-icon {
  position: absolute;
  right: -24px;
  bottom: 14px;
}

.infomation-popup {
  box-shadow: 0px 0px 30px rgba(85, 85, 85, 0.15);
  border-radius: 10px;
  padding: 10px;
  font-size: 11px;
  position: absolute;
  max-width: 160px;
  width: 100%;
  right: -220px;
  color: $color-primary;
  text-align: left;
  background-color: $color-white;
}
@media screen and (max-width: 1232px) {
  .products-panel-cards {
    .mbi-card {
      &:nth-child(3n) {
        margin-right: 20px;
      }

      &:nth-child(4n) {
        margin-right: 20px;
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .cart-card {
    width: 100%;
  }
}

@media screen and (max-width: 1024px) {
  .products-panel-cards {
    //grid-template-columns: repeat(3, 1fr);
    .mbi-card {
      min-width: 270px;
      max-width: calc(100% / 2 - 6px) !important;
    }
  }
  .cart-item-name {
    width: 45%;
  }

  .cart-card {
    width: 100%;
    .card-empty {
      font-size: 14px;
      font-weight: 700;
      color: $color-gray-light7;
      text-align: center;
      padding: 20px 120px 20px 120px;
    }
  }
}

@media screen and (max-width: 936px) {
  .product-panel {
    display: flex;
    flex-direction: column;
    background: $color-gray-light5;
    padding: 5px;
    border-radius: 8px;
  }
  .products-panel-cards {
    .mbi-card {
      &:nth-child(3n) {
        margin-right: 20px;
      }
      &:nth-child(2n) {
        margin-right: 0;
      }
      &:nth-child(4n) {
        margin-right: 0;
      }
    }
  }
}

@media screen and (max-width: 752px) {
  .products-panel-cards {
    .mbi-card {
      min-width: 100%;
      width: 100%;
      margin: 0 0 20px 0 !important;
    }
  }
  .product-panel .product-panel-header {
    .vehicle-details {
      width: 100%;
      margin-bottom: 10px;
    }
    .btn-primary {
      width: 100%;
    }
  }
  .page-main-wrapper {
    margin: 0;
  }
}

@media screen and (max-width: 767px) {
  .vehicle-summary-wrap {
    display: block;
    width: 100%;
  }
  .vehicle-summary-card {
    width: 100%;
    margin-right: 0;
  }
  .richtext-cart-card {
    width: 100%;
  }
  .cart-card {
    background: $color-gray-light5;
    padding: 10px;
    width: 100%;
    margin-right: 0;
    margin-left: 0;
  }
  .products-panel-cards {
    .mbi-card {
      margin-bottom: 10px;
      .mbi-btn-wrapper {
        display: block;
        a {
          display: block;
          margin-right: 0 !important;
          margin-bottom: 10px;
          width: 100%;
          box-sizing: border-box;
          margin-left: 0 !important;
        }
      }
    }
  }

  .infomation-popup {
    max-width: 130px;
    width: 100%;
    right: -190px;
  }
  .mbi-id {
    margin-top: 10px;
  }
}

@media screen and (max-width: 480px) {
  .price-meter {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 375px) {
  .infomation-popup {
    max-width: 90px;
    width: 100%;
    right: -140px;
  }
}

@media screen and (max-width: 768px) {
  .vehicle-summary-card {
    flex-basis: 100%;
    margin-right: 0;
  }
  .cart-card {
    flex-basis: 100%;

    .pading-adjest {
      padding-left: 0px;
    }
    .width-adjust {
      width: 70px;
    }
    .column {
      h4 {
        font-size: 11px;
      }
      h4 {
        font-size: 11px;
      }
    }
    .card-empty {
      font-size: 14px;
      font-weight: 700;
      color: $color-gray-light7;
      text-align: center;
      padding: 0px 0px 0px 0px;
    }
  }
  .cart-item-name {
    width: 30%;
  }
}

@media screen and (max-width: 375px) {
  .cart-card {
    .column.txt-center {
      margin: 0 5px;
    }
    .column {
      h4 {
        font-size: 10px;
      }
      h6 {
        font-size: 10px;
      }
    }
    .width-adjust {
      width: 60px;
    }
  }
}

@media screen and (max-width: 320px) {
  .products-panel-cards {
    .mbi-card {
      .icon-header-panel {
        height: 109px;
      }
    }
  }
}

@media screen and (max-width: 280px) {
  .cart-card {
    .column.txt-center {
      margin: 0 5px;
    }
    .column {
      h4 {
        font-size: 9px;
      }
    }
    .width-adjust {
      width: 50px;
    }
  }
}

/* Medium devices */
@media only screen and (max-width: 1024px) {
  .price-meter {
    span,
    h3 {
      font-size: 16px;
    }
  }
}

/* Small devices */
@media only screen and (max-width: 768px) {
  .price-meter {
    position: absolute;
    top: -20px;
    right: 20px;
    margin-bottom: 5px;
    width: 100%;
    justify-content: flex-end;
    span,
    h3 {
      font-size: 14px;
      color: #fff;
    }
  }
  .vehicle-summary-wrap {
    margin-bottom: 0;
    .no-product {
      display: none;
    }
    .product-selected {
      display: block;
    }
  }
  .vehicle-summary-card {
    margin-bottom: 5px;
    padding: 5px;
    > div {
      margin-bottom: 0;
    }
  }
  .product-panel {
    padding: 8px 10px 0 10px !important;
    .section-header {
      margin-bottom: 10px;
      .main-title {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
    .products-panel-cards {
      justify-content: space-between;
    }
    .mbi-card {
      position: relative;
      width: calc(50% - 5px);
      min-width: calc(50% - 5px);
      margin-bottom: 5px !important;
      &.border-selected {
        border-top: 2px solid #1caf0a !important;
        margin-top: -2px !important;
      }
      &.border-choosen {
        border-top: 0 !important;
        margin-top: 0 !important;
      }
      &:hover {
        &.border-choosen {
          border-top: 0 !important;
          margin-top: 0 !important;
        }
      }
      .card-body {
        padding: 5px 10px 60px 10px;
        .price {
          margin: 2px 5px 2px 0;
          .flex {
            flex-direction: column;
            span {
              font-size: 12px !important;
            }
          }
          .small-price {
            margin-top: 0 !important;
          }
        }
        .acc-body {
          .overlay {
            position: fixed !important;
            top: -2px;
            left: 0;
            height: 100vh;
            width: 100vw;
            background-color: rgba(#000, 0.2);
            border: 0;
            .popup {
              top: 25%;
              width: 330px;
            }
          }
        }
      }
      .icon-header-panel {
        height: 30px;
        img {
          max-width: 32%;
        }
      }
      .mbi-title {
        font-size: 12px;
        padding-bottom: 0;
      }
      .mbi-btn-wrapper {
        position: absolute;
        bottom: 0;
        width: calc(100% - 20px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        a {
          margin-bottom: 5px;
          width: 100%;
          border-radius: 5px;
          padding: 5px;
        }
      }
    }
  }
  .cart-card {
    display: none;
    margin-bottom: 5px;
    padding: 10px;
    .column {
      h6,
      h4 {
        margin: 0;
      }
    }
    .card-empty {
      font-weight: 500;
    }
  }
}
